import React from 'react'
import styled from 'styled-components'
import { strings } from '@constants'
import { Pill, SectionHeader } from '@base'
import { Spacings } from '@styles'
import { IconWrapper, ViewInfoCard } from '@pages/Admin/Dashboard/CompanyReport'
import { DetailsWrapper, PillWapper } from '../../styles'

const PillTextWapper = styled(IconWrapper)`
	position: relative;
`

const BillingAddress = ({ financialData }) => {
	const billingAddressList = financialData?.billing_address_list?.filter((obj, index) => {
		return index === financialData?.billing_address_list.findIndex((o) => obj.city === o.city)
	})
	return (
		<>
			<DetailsWrapper>
				<SectionHeader>{strings('billing', 'address')}</SectionHeader>
				<PillWapper>
					{billingAddressList?.map((item, index) => (
						<PillTextWapper>
							<ViewInfoCard>{item?.complete_address && <span>{item?.complete_address}</span>}</ViewInfoCard>
							<Pill label={`${item?.city || ''}: `} value={item?.state} margin={`${Spacings.SPACING_1B}`} key={index} />
						</PillTextWapper>
					))}
				</PillWapper>
			</DetailsWrapper>
		</>
	)
}

export default BillingAddress
