import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Button, Calendar, DrawerBodyWrapper, DrawerFooterWrapper, H4, H5, Input, RadioButton, Select, TOAST_TYPE, UploadButton } from '@base'
import { FormItemWrapper, InputWrapper } from '../Pdc/PdcForm'
import { CREDIT_LIMIT_STATUS, CREDIT_LIMIT_TYPES, MODAL_TYPES, strings } from '@constants'
import { RadioButtonWrapper } from '@pages/Admin/Content/styles'
import { Label } from '../../styles'
import { Spacings } from '@styles'
import { getOrganisationKycData, uploadKYCDocument, createCreditLimit, uploadSupportedDoc } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer, toggleFileViewer, toggleModal } from '@data/state/action/root'
import { useForceUpdate } from '@hooks'

const DocumentUploadWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${Spacings.SPACING_5B};
	gap: ${Spacings.SPACING_3B};
`

const TitleWrapper = styled(H4)`
	margin-bottom: ${Spacings.SPACING_1B};
	font-weight: 400;
`
const DocumentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`

const HeaderWrapper = styled(H5)`
	align-self: center;
	flex: 1;
`
const HeaderSpanWrapper = styled.span`
	color: ${({ theme, color }) => (color ? theme.palette.background[color] : theme.palette.text.greydark)};
	font-size: ${Text.SMALL};
`

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${Spacings.SPACING_3B};
`

const statusTypes = Object.entries(CREDIT_LIMIT_STATUS).map(([key, value]) => ({ key, label: value }))

const deriveFormState = (data) => {
	let _state = {
		user_detail: {
			id: 'user-detail',
			placeholder: strings('user', 'details'),
			label: strings('user', 'details'),
			value: {},
			primaryKey: 'user_id',
			displayKey: 'full_name',
			disabled: false,
			required: false,
		},
		type: {
			value: CREDIT_LIMIT_TYPES.ADHOC,
		},
		sanction_date: {
			id: 'sanction-date',
			label: strings('sanction_date'),
			placeholder: strings('enter', 'sanction_date'),
			value: new Date().getTime(),
			disabled: false,
			required: false,
		},
		expiry_date: {
			id: 'expiry_date',
			label: strings('expiry_date'),
			placeholder: strings('expiry_date'),
			value: new Date().getTime(),
			disabled: false,
			required: false,
		},
		status: {
			id: 'status',
			label: strings('status'),
			placeholder: strings('select', 'status'),
			value: statusTypes.find((item) => item.label === CREDIT_LIMIT_STATUS.ACTIVE),
			data: statusTypes,
			primaryKey: 'key',
			displayKey: 'label',
			disabled: false,
			required: false,
		},
		amount: {
			id: 'amount',
			label: strings('amount'),
			placeholder: strings('enter', 'amount'),
			value: '',
			disabled: false,
			required: true,
		},
		aadhaar: {
			id: 'aadhaar',
			value: '',
			disabled: false,
			required: true,
		},
		pan_doc: {
			id: 'pan-doc',
			value: '',
			disabled: false,
			required: true,
		},
		bank_statement: {
			id: 'bank-statement',
			value: '',
			disabled: false,
			required: false,
		},
		financial_document: {
			id: 'financial-document',
			value: '',
			disabled: false,
			required: false,
		},
		supported_documents: {
			id: 'supported-document',
			value: [],
			disabled: false,
			required: false,
		},
		gst_certificate: {
			id: 'gst-cerificate',
			value: '',
			disabled: false,
			required: true,
		},
	}
	return _state
}

const AddCreditLimit = ({ orgId, userData, outletUpdate }) => {
	const dispatch = useDispatch()
	const [forceUpdate, update] = useForceUpdate()
	const loggedInUser = useSelector((state) => state.getIn(['user', 'user']))

	const [formState, setFormState] = useState(deriveFormState())
	const [curDocType, setCurDocType] = useState('')

	const setKycData = (data) => {
		setFormState((_state) => {
			return {
				..._state,
				['pan_doc']: {
					..._state['pan_doc'],
					value: data?.documents?.pan ?? '',
				},
				['aadhaar']: {
					..._state['aadhaar'],
					value: data?.documents?.aadhaar ?? '',
				},
				['bank_statement']: {
					..._state['bank_statement'],
					value: data?.documents?.bank_statement ?? '',
				},
				['gst_certificate']: {
					..._state['gst_certificate'],
					value: data?.documents?.gst_certificate ?? '',
				},
				['financial_document']: {
					..._state['financial_document'],
					value: data?.documents?.financial_document ?? '',
				},
			}
		})
	}

	useEffect(() => {}, [forceUpdate])
	useEffect(() => {
		getOrganisationKycData(orgId)
			.then((response) => {
				setKycData(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [])

	const updateState = (key) => (value) => {
		setFormState((_s) => ({
			..._s,
			[key]: {
				..._s[key],
				value,
			},
		}))
	}

	const downloadDocument = (filePath) => {
		dispatch(toggleFileViewer(true, { files: filePath }))
	}
	const uploadFileHandler =
		(key, typeOfProperty = null) =>
		(file) => {
			dispatch(showLoader(true))
			uploadKYCDocument(file, key, orgId, typeOfProperty)
				.then((res) => {
					dispatch(showToast(true, strings('upload_success'), { type: TOAST_TYPE.SUCCESS }))
					setKycData(res)
				})
				.catch((err) => console.log(err))
				.finally(() => {
					update()
					dispatch(showLoader(false))
				})
		}

	const submitHandler = () => {
		createCreditLimit({
			amount: formState?.amount?.value,
			created_at: new Date().getTime(),
			created_by: loggedInUser.getUserDetails()?.user_id,
			expiry_date: formState?.expiry_date?.value,
			sanction_date: formState?.sanction_date?.value,
			status: formState?.status?.value?.key,
			type: formState?.type?.value,
			user_details: userData,
			supported_documents: formState?.supported_documents?.value?.length > 0 ? formState?.supported_documents?.value : null,
		})
			.then((response) => {
				dispatch(showToast(true, strings('msg_credit_limit_added_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				console.log(error)
				dispatch(showToast(true, strings('msg_credit_limit_added_fail'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				outletUpdate()
				dispatch(toggleDrawer(false))
			})
	}

	const supportDocUploader = (file) => {
		dispatch(showLoader(true))
		uploadSupportedDoc(file)
			.then((response) => {
				let _supDoc = formState.supported_documents?.value ?? []
				_supDoc.push({ type: curDocType, path: response })
				updateState('supported_documents', _supDoc)
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				setCurDocType('')
				dispatch(showLoader(false))
			})
	}

	const deleteSupportDoc = (index) => {
		dispatch(
			toggleModal(true, MODAL_TYPES.CONFIRMATION, {
				overlayClickable: true,
				title: strings('msg_are_you_sure_want_to_delete_doc'),
				confirmAction: (actionType) => {
					dispatch(showLoader(true))
					if (actionType) {
						const _supDocList = formState.supported_documents.value?.filter((item, _index) => _index !== index)
						updateState('supported_documents')(_supDocList)
					}
					dispatch(showLoader(false))
					dispatch(toggleModal(false))
				},
			})
		)
	}

	const disabled = useMemo(() => {
		return Object.values(formState).some((d) => d.required && !d.value) || curDocType !== ''
	}, [formState, curDocType])

	const SingleDocumentUpload = ({ type, file = null, mandatory = false, title }) => {
		return (
			<DocumentWrapper>
				<HeaderWrapper>
					{title} {mandatory && <HeaderSpanWrapper color='redLight'>{strings(['(', 'required', ')'])}</HeaderSpanWrapper>}
				</HeaderWrapper>
				<ButtonWrapper>
					<Button xs type='secondary' disabled={!file} onClick={() => downloadDocument(file)}>
						{strings('view')}
					</Button>

					<UploadButton xs variant='destructive' label={!file ? strings('upload') : strings('change')} onChange={uploadFileHandler(type)} />
				</ButtonWrapper>
			</DocumentWrapper>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				<FormItemWrapper>
					<InputWrapper>
						<Calendar
							label={formState.sanction_date?.label}
							id={formState.sanction_date?.id}
							value={formState.sanction_date?.value}
							onChange={updateState('sanction_date')}
						/>
					</InputWrapper>
					<InputWrapper>
						<Calendar
							label={formState.expiry_date?.label}
							id={formState.expiry_date?.id}
							value={formState.expiry_date?.value}
							onChange={updateState('expiry_date')}
						/>
					</InputWrapper>
				</FormItemWrapper>
				<FormItemWrapper>
					<Select
						id={formState?.status?.id}
						label={formState?.status?.label}
						placeholder={formState?.status?.placeholder}
						value={formState?.status?.value}
						data={formState?.status?.data}
						primaryKey={formState?.status?.primaryKey}
						displayKey={formState?.status?.displayKey}
						disabled={formState?.status?.disabled}
						required={formState?.status?.required}
						onChange={updateState('status')}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Input
						id={formState.amount?.id}
						type='number'
						label={formState?.amount?.label}
						placeholder={formState?.amount?.placeholder}
						value={formState?.amount?.value}
						disabled={formState?.amount?.disabled}
						required={formState?.amount?.required}
						onChange={updateState('amount')}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<div>{strings('type', ':')}</div>
					<RadioButtonWrapper>
						<RadioButton
							id='adhoc-radio'
							size='1.125'
							checked={formState?.type?.value === CREDIT_LIMIT_TYPES.ADHOC}
							value={CREDIT_LIMIT_TYPES.ADHOC}
							onChange={(e) => updateState('type')(e.target.value)}
						/>
						<Label id='adhoc-radio' margin={`0 ${Spacings.SPACING_1B}`}>
							{strings('ADHOC')}
						</Label>
					</RadioButtonWrapper>
					<RadioButtonWrapper>
						<RadioButton
							id='enhancement-radio'
							size='1.125'
							checked={formState?.type?.value === CREDIT_LIMIT_TYPES.ENHANCEMENT}
							value={CREDIT_LIMIT_TYPES.ENHANCEMENT}
							onChange={(e) => updateState('type')(e.target.value)}
						/>
						<Label id='enhancement-radio' margin={`0 ${Spacings.SPACING_1B}`}>
							{strings('enhancement')}
						</Label>
					</RadioButtonWrapper>
					<RadioButtonWrapper>
						<RadioButton
							id='base-radio'
							size='1.125'
							checked={formState?.type?.value === CREDIT_LIMIT_TYPES.BASE}
							value={CREDIT_LIMIT_TYPES.BASE}
							onChange={(e) => updateState('type')(e.target.value)}
						/>
						<Label id='base-radio' margin={`0 ${Spacings.SPACING_1B}`}>
							{strings('base')}
						</Label>
					</RadioButtonWrapper>
				</FormItemWrapper>

				<DocumentUploadWrapper>
					<TitleWrapper>{strings('msg_upload_document', 'director')}</TitleWrapper>
					<FormItemWrapper>
						<SingleDocumentUpload
							type='pan'
							title={strings('pan')}
							mandatory={formState?.pan_doc?.required}
							file={formState?.pan_doc?.value}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<SingleDocumentUpload
							type='aadhaar'
							title={strings('aadhaar')}
							mandatory={formState?.aadhaar?.required}
							file={formState?.aadhaar?.value}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<SingleDocumentUpload
							type='gst_certificate'
							title={strings('gst')}
							mandatory={formState?.gst_certificate?.required}
							file={formState?.gst_certificate?.value}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<SingleDocumentUpload
							type='bank_statement'
							title={strings('bank', 'statement')}
							mandatory={formState?.bank_statement?.required}
							file={formState?.bank_statement?.value}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<SingleDocumentUpload
							type='financial_document'
							title={strings('financial', 'document')}
							mandatory={formState?.financial_document?.required}
							file={formState?.financial_document?.value}
						/>
					</FormItemWrapper>
				</DocumentUploadWrapper>
				<DocumentUploadWrapper>
					<TitleWrapper>{strings('supported', 'documents')}</TitleWrapper>
					{formState.supported_documents?.value?.map((item, index) => (
						<FormItemWrapper>
							<DocumentWrapper>
								<HeaderWrapper>{item.type}</HeaderWrapper>
								<ButtonWrapper>
									<Button xs type='secondary' onClick={() => downloadDocument(item?.path)}>
										{strings('view')}
									</Button>
									<Button
										xs
										variant='destructive'
										onClick={() => {
											deleteSupportDoc(index)
										}}
									>
										{strings('delete')}
									</Button>
								</ButtonWrapper>
							</DocumentWrapper>
						</FormItemWrapper>
					))}
					<FormItemWrapper>
						<Input
							id='supported-documents'
							value={curDocType}
							type='text'
							label={strings('document', 'type')}
							placeholder={strings(['enter', 'document', 'type'])}
							onChange={(value) => setCurDocType(value)}
							// required={true}
						/>
						<DocumentWrapper>
							<UploadButton variant='destructive' xs label={strings('upload')} disabled={!curDocType} onChange={supportDocUploader} />
						</DocumentWrapper>
					</FormItemWrapper>
				</DocumentUploadWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button xs onClick={submitHandler} disabled={disabled}>
					{strings('add')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddCreditLimit
